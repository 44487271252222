<template>
    <div class="outside">
        <div class="potential">
            <rxNavBar title="变更详情"></rxNavBar>
        </div>
        <div class="blankWeight"></div>

    <div class="panel" >
    <div class="top-title-green" style="z-index:10000">{{$route.query.approvalStatusStr}}</div>            
      <van-row class="info">
        <van-col span="12">
          <p>申请人：<span>{{$route.query.applyStaffName}}</span></p>
        </van-col>
        <van-col span="12">
          <p>申请原因：<span>{{$route.query.dictionaryTitle}}</span></p>
        </van-col>
        <van-col span="12">
          <p>备注：<span>{{$route.query.applyMemo}}</span></p>
        </van-col>
        <van-col span="12">
          <p>申请时间：<span>{{$route.query.applyDate}}</span></p>
        </van-col>
        <van-col span="12">
          <p>审批人：<span>{{$route.query.approvalApplicantStaff}}</span></p>
        </van-col>
        <van-col span="12">
          <p>审批意见：<span>{{$route.query.approvalOpinion}}</span></p>
        </van-col> 
        <van-col span="12">
          <p>审批时间：<span>{{$route.query.approvalTime}}</span></p>
        </van-col>                         
      </van-row>
    </div>         
        <!-- 顶部固定区域-->
        <div class="header">
            <!-- 合同切换区域-->
            <div class="choose">
                <div class="choose-one">
                    <div :class="type=='0'?'choose-one-textActive':'choose-one-text'" @click="checkData('0')">新增</div>
                    <div class="choose-one-icon" v-show="type=='0'"></div>
                </div>
                <div class="choose-line"></div>
                <div class="choose-one">
                    <div :class="type=='2'?'choose-one-textActive':'choose-one-text'" @click="checkData('2')">修改</div>
                    <div class="choose-one-icon" v-show="type=='2'"></div>
                </div>
                 <div class="choose-line"></div>
                <div class="choose-one">
                    <div :class="type=='1'?'choose-one-textActive':'choose-one-text'" @click="checkData('1')">删除</div>
                    <div class="choose-one-icon" v-show="type=='1'"></div>
                </div>                 
            </div>
        </div>
        <!--合同列表区域-->
     
    <div style="margin-top:20px;"></div>  
        <div class="contracts">
            <van-pull-refresh v-model="refreshing"  @refresh="onRefresh">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        :finished-text="noContent ? '' : '已经到底了'"
                        @load="this.totalBatchAlertApplication"
                >
                    <!--合同列表-->
                    <div class="contract" v-for="(item,index) in totalChangeApplicationList" :key="index">
                        <div class="top-title-green">{{item.roomName?item.roomName:'公区'}}间</div>                        
                        <div class="position" >{{item.goodsallocationName}}</div>
    <div class="panel">
      <van-row class="info">
        <van-col span="24">
          <p>区域：<span>{{item.regionalLocationName?item.regionalLocationName:''}}</span></p>
        </van-col>
        <van-col span="12">
          <p>金额：<span>{{item.money}}</span></p>
        </van-col>
        <van-col span="12">
          <p>数量：<span>{{item.totalCount}}</span></p>
        </van-col>
        <van-col span="12">
          <p>供应商：<span>{{item.supplierName}}</span></p>
        </van-col>
        <van-col span="12">
          <p>加项原因：<span>{{item.reasonAdditionTitle}}</span></p>
        </van-col>
        <van-col span="24">
          <p>备注：<span>{{item.remarks}}</span></p>
        </van-col>        
      </van-row>
    </div>                         
                    </div>
                    <common-empty v-if="noContent"></common-empty>
                </van-list>
            </van-pull-refresh>
        </div>
    </div>
</template>

<script>
    import {NavBar, Picker, Popup,PullRefresh,Overlay,    Row,
    Col, } from 'vant';
    import Vue from 'vue';
    import { List ,Toast,Dialog} from 'vant';
    Vue.use(List);
    import {
        queryHardcoverSoftOutfit,querydistributionitemChangeitem
    } from "../../getData/getData";
    import {responseUtil, checkAuthList, getStaffId, globaluserId} from "../../libs/rongxunUtil";
    import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
    export default {
        name: "contractNew",
        components:{
            [NavBar.name]:NavBar,
            [Toast.name]:Toast,
            [Dialog.name]:Dialog,
            [Picker.name]: Picker,
            [Popup.name]:Popup ,
            rxNavBar,
            [PullRefresh.name]:PullRefresh,
            [Overlay .name]: Overlay ,    
            [Row.name]: Row,
            [Col.name]: Col,                 
        },
        data(){
            return{
                refreshing:false,
                page:1,
                loading: false,
                finished: false,
                noContent:false,
                totalChangeApplicationList:[],    //列表
                type:0,
                page:{currentPage:1,numberPage:3}
            }
        },
        created() {
            this.rtid=this.$route.query.rtid
        },
        mounted() {
        },
        methods:{
            onRefresh() {
                // 清空列表数据
                this.finished = false;
                this.totalChangeApplicationList = []
                this.page.currentPage = 1
                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.totalBatchAlertApplication();
                responseUtil.alertMsg(this,"刷新成功")
            },
            //变更申请列表
            totalBatchAlertApplication(){
                let that=this
                let initData={}
                initData.user_id = getStaffId()
                initData.id=that.$route.query.id
                initData.currentPage=that.page.currentPage
                initData.numberPage=that.page.numberPage
                initData.type=that.type
                console.log(initData)
                querydistributionitemChangeitem(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response)
                        let data=response.data.data.data
                        if(data){
                            for(let i=0;i<data.length;i++){
                                that.totalChangeApplicationList.push(data[i])
                            } 
                        }                        
                        that.loading=false 
                        if (that.totalChangeApplicationList.length >=response.data.data.pageCount) {
                            that.finished = true;
                        } 
                        that.page.currentPage++  
                    })
                })                  
            },
            //切换 新增,删除，修改
            checkData(e){
                this.type=e
                this.totalChangeApplicationList=[]
                this.loading=true
                this.finished=false   
                this.noContent = false             
                this.page.currentPage=1
                this.totalBatchAlertApplication()
            },          
        }
    }
</script>

<style lang="less" scoped>
  @redius: 8px;
  @border: 1px solid rgb(244, 244, 244);
    .nav-top /deep/ .van-nav-bar{
        background-color: white;
        border-bottom: 2px solid white;
    }
    .outside{
        overflow: hidden;
        width: 100%;
    }
    .small-icon{
        width: 8px;
        height: 8px;
        margin-left: 5px;
        background-image: url("../../assets/images/small-icon.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
    }
    .small-icon-rotate{
        width: 8px;
        height: 8px;
        margin-left: 5px;
        margin-bottom: 5px;
        background-image: url("../../assets/images/small-icon.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        transform:rotate(180deg);
    }
    .select{
        width: 100%;
        height: 35px;
        line-height: 30px;
        font-size: 14px;
        text-align: left;
        margin-left: 15px;
        color: #999999;
    }
    .selectActive{
        color: #fe5e3a;
    }
    .selectList{
        width: 100%;
        padding: 5px 0 14px 0;
        background-color: white;
    }
    .navbar{
        background-color: white;
        border-bottom: 2px solid white;
    }
    .header{
        margin-top:20px;
        width: 100%;
        height: auto;
 
        z-index: 8;
        top: 50px;
        background-color: #f8f8f8;
    }
    .choose{
        width: 100%;
        height: 56px;
        background-color: white;
        display: flex;
    }
    .choose-one{
        width: 187px;
        text-align: center;
    }
    .choose-one-textActive{
        font-size: 14px;
        color: #fe5e3a;
        font-weight: bold;
        margin-top: 10px;
    }
    .choose-one-text{
        font-size: 14px;
        color: #999999;
        margin-top: 10px;
    }
    .choose-one-icon{
        width: 20px;
        height: 4px;
        border-radius: 3px;
        background-color: #fe5e3a;
        margin:0 auto;
    }
    .choose-line{
        width: 1px;
        height: 17px;
        margin-top: 13px;
        background-color: #e0e0e0;
    }
    .search{
        margin: 15px 20px 0 20px;
        height: 35px;
        border-radius: 30px;
        background-color: #f4f4f4;
        display: flex;
        align-items: center;
    }
    .search-icon{
        width: 25px;
        height: 25px;
        margin-left: 10px;
        background-image: url("../../assets/images/search-icon.png");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    input{
        font-size: 14px;
        margin-left: 15px;
        margin-top: 3px;
        background-color: #f4f4f4;
        border: 0;
        width: 250px;
    }
    input::-webkit-input-placeholder{
        color: #d9d9d9;
    }
    .types{
        width: 100%;
        height: 45px;
        display: flex;
        align-items: center;
    }
    .type{
        font-size: 14px;
        color: #fe5e3a;
        margin-left: 15px;
    }
    .typeActive{
        color: #fe5e3a;
        font-weight: bold;
    }
    .type-line{
        width: 1px;
        height: 12px;
        margin-top: -2px;
        background-color: #e0e0e0;
    }
    .leaseChange{
        font-size: 14px;
        color: black;
        margin-left: 15px;
    }
    .leaseChangeActive{
        font-size: 14px;
        color: #fe5e3a;
        margin-left: 15px;
    }
    .contracts{
        width: 100%;
        overflow: hidden;
        position: relative;
    }
    .contract{
        position: relative;
        margin: 0 15px 15px 15px;
        height: auto;
        background-color: white;
        border-radius: 8px;
        overflow: hidden;
    }
    .top-title-orange{
        margin: 0;
        width: 45px;
        height: 20px;
        font-size: 10px;
        text-align: center;
        line-height: 22px;
        border-radius: 8px 0;
        background: linear-gradient(to right,#ffbe72,#ff6c41);
        color: white;
    }
    .top-title-green{
        margin: 0;
        width: 45px;
        height: 20px;
        font-size: 10px;
        text-align: center;
        line-height: 22px;
        border-radius: 8px 0;
        background: linear-gradient(to right,#63cb96,#30d0ac);
        color: white;
    }
    .top-title-red{
        margin: 0;
        width: 45px;
        height: 20px;
        font-size: 10px;
        text-align: center;
        line-height: 22px;
        border-radius: 8px 0;
        background: linear-gradient(to right,#fc401b,#e02321);
        color: white;
    }
    .position{
        margin: 15px 50px 0 15px;
        height: auto;
        text-align: left;
        font-size: 16px;
        font-weight: bold;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .tel-icon{
        width: 25px;
        height: 25px;
        position: absolute;
        top: 32px;
        right: 20px;
        background-image: url("../../assets/images/relation-phone.png");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    .details{
        margin-top: 15px;
        width: 100%;
        height: auto;
        border-bottom: 1px solid #e0e0e0;
        display: flex;
    }
    .details .info{
        width: 85.5px;
        height: auto;
        text-align: center;
    }
    .details .info .order{
        width: 100%;
        height: 20px;
        font-size: 14px;
        line-height: 20px;
        color: #999999;
    }
    .details .info .order-name{
        width: 100%;
        height: 20px;
        font-size: 14px;
        line-height: 20px;
        color: #fe5e3a;
        margin-top: 10px;
        margin-bottom: 15px;
        white-space: nowrap;
        text-overflow:ellipsis;
        overflow: hidden;
        font-weight: bold;
    }
    .details .line{
        width: 1px;
        height: 40px;
        background-color: #e0e0e0;
        margin-top: 8px;
    }
    .contract-info{
        width: 100%;
        height: auto;
        padding: 15px 0 10px 0;
        border-bottom: 1px solid #e0e0e0;

    }
    .contract-text{
        height: 25px;
        line-height: 25px;
        text-align: left;
        margin-left: 20px;
        color: #999999;
        font-size: 14px;
        width:40%;
        display:inline-block;
    }
    .contract-longText{
        height: 25px;
        line-height: 25px;
        text-align: left;
        margin-left: 20px;
        color: #999999;
        font-size: 14px;

    }    
    .bottom{
        width: 330px;
        height: 60px;
        padding-right: 15px;
        overflow: hidden;
    }
    .bottom-sign{
        float: right;
        width: 80px;
        height: 30px;
        margin-top: 15px;
        margin-left: 11px;
        line-height: 30px;
        background: linear-gradient(to right,#ffbe72,#ff6c41);
        color: white;
        font-size: 12px;
        text-align: center;
        border-radius: 8px;
    }
    .bottom-change{
        float: right;
        width: 80px;
        height: 30px;
        margin-top: 15px;
        margin-left: 11px;
        line-height: 30px;
        background: linear-gradient(to right,#7cc7ff,#3596fd);
        color: white;
        font-size: 12px;
        text-align: center;
        border-radius: 8px;
    }
    .bottom-invalid{
        float: right;
        width: 80px;
        height: 30px;
        margin-top: 15px;
        margin-left: 11px;
        line-height: 30px;
        background: linear-gradient(to right,#E02020,#FF431B);
        color: white;
        font-size: 12px;
        text-align: center;
        border-radius: 8px;
    }
    .bottom-property{
        float: right;
        width: 90px;
        height: 30px;
        margin-top: 15px;
        margin-left: 11px;
        right: 15px;
        line-height: 30px;
        background: linear-gradient(to right,#ffbe72,#ff6c41);
        color: white;
        font-size: 12px;
        text-align: center;
        border-radius: 8px;
    }
    .bottom-changeLease{
        float: right;
        width: 80px;
        height: 30px;
        margin-top: 15px;
        margin-left: 11px;
        line-height: 30px;
        background: linear-gradient(to right,#7cc7ff,#3596fd);
        color: white;
        font-size: 12px;
        text-align: center;
        border-radius: 8px;
    }
    .blankWeight{
        height: 20px;
    }
    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    .block {
        width: 76%;
        height: 21.5%;
        background-color: #fff;
        border-radius: 8px;
        overflow: hidden;
    }
    /*    获取密码DIV*/
    .getPassword{
        background: url("../../assets/images/ContactPerson-img.png") no-repeat center center,
        linear-gradient(to right,#FFC274 ,#FF5D3B);
        text-align: center;
        height: 50px;
        line-height: 53px;
        color: white;
        background-size: 100%;
        font-size: 16px;
    }
    /*    门锁密码*/
    .LockPassWord{
        margin-top: 20px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        line-height: 14px;
    }

    /*传入的密码值*/
    .password{
        color: #ff8247;
        font-size: 20px;
        margin-top: 40px;
        font-family: SimSun;
    }
    /*关闭按钮*/
    .closeImg{
        margin-top: 100px;
        height: 30px;
        width: 30px;
        border: 1px solid white;
        border-radius: 50%;
        background: url("../../assets/images/GetDoorPassword-closeImg.png") no-repeat center center;
        background-size:18px 18px;
    } 
        .van-overlay{
            background-color: rgba(80,80,80,.7);;
        }    
    .bottom_iconDiv{
        float: left;
        width: 120px;
        height: 30px;
    } 
    .pwd_IMG{
        float: left;
        width: 30px;
        height: 25px;
        margin-left: 15px;
        margin-top: 3px;
    }
    .icon{
        float: left;
        width: 120px;
        height: 30px;
        margin-top: 10px;
    } 
    .getPwd{
        font-size: 14px;
        float: left;
        color: #9a9a9a;
        margin-top: 6px;
    }  
    .subtitle {
      padding: 5px 0px;
      color: rgb(244, 99, 76);
      margin-top: 15px;
      font-weight: 700;
      font-size: 12px;
      margin-left:15px;
    }   
    .panel {
      width: 100%;
      background-color: rgb(255, 255, 255);
      border-radius: @redius;
      position: relative;
      font-size: 14px;
      text-align: left;
      overflow: hidden;


      &.withMargin {
        margin: 16px 0;
      }

      &>p {
        padding: 15px 15px 0px;
        font-weight: bolder;
        /*color: #252525;*/

      }

      &>p>span {
        font-weight: normal;
        color: rgb(190, 190, 190);
      }

      .state {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 20px;
        font-weight: bold;
        height: 32px;
        line-height: 32px;
        width: 60px;
        display: block;
        background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        background: -webkit-linear-gradient(left, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        /* Safari 5.1 - 6.0 */
        background: -o-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        /* Opera 11.1 - 12.0 */
        background: -moz-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        /* Firefox 3.6 - 15 */
        background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        border-top-left-radius: 16px;
        border-bottom-right-radius: 16px;
        color: rgb(255, 255, 255);
        text-align: center;
        transform: scale(0.5);
        transform-origin: 0% 0%;
        font-family: sans-serif;
        padding: 0 10px;
      }

      .title {
        width: 315px;
        padding: 0 15px;
        font-weight: bolder;
        color: rgb(0, 0, 0);
        font-size: 16px;
        padding-top: 20px;

        span {
          font-size: 12px;
          font-weight: normal;
          color: rgba(153, 153, 153, 1);
        }
      }
      .allprice{
        padding-bottom: 4px;
      }
      /*审核结果,服务人员 审批意见*/
      .shenhe,.fuwu,.yijian{
        font-size: 15px;
      }
      .describe {
        padding-bottom: 15px;
        padding-top: 10px;
        color: rgb(82, 82, 82);
        font-weight: normal;
      }

      .info {
        padding: 15px;
        line-height: 16px;

        &.noPadding {
          padding: 0 15px;
        }

        p {
          margin: 4px 0;
          font-weight: bolder;

          span {
            font-size: 13px;
            font-weight: normal;
            color: rgb(110, 110, 110);
          }
        }

        .floatR {
          float: right;
        }

        .total_color {
          color: rgba(255, 93, 59, 1);
        }
      }

      .text_body {
        padding: 15px;

        .mes_body {
          border-radius: @redius;
          background-color: rgb(250, 250, 250);
        }
      }
    }                           
</style>
